.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url(https://db.onlinewebfonts.com/c/81c94f7de46932058605da3de7a35798?family=DIN+Next+Rounded+LT+W04+Regular);
@import url(https://db.onlinewebfonts.com/c/81c94f7de46932058605da3de7a35798?family=DIN+Next+Rounded+LT+W04+Light);
@import url(https://db.onlinewebfonts.com/c/643121b5d2cc9943eff9b59054cc29ba?family=DIN+Next+Rounded+LT+W01+Light);
@import url(https://db.onlinewebfonts.com/c/d78f5d2c76185fa07aaf8dd729eef33e?family=DIN+Next+LT+Arabic+Light);
@import url(https://db.onlinewebfonts.com/c/54f4891696ad7a79999d5e22d33672f0?family=DIN+Next+Slab+Pro);
@import url(https://db.onlinewebfonts.com/c/e0a2ae0f56c8a00b36ffa21693e00261?family=Monotype.com+V1);

html {
  --nfai-text-font-family: "DIN Next Rounded LT W01 Light", "Arial", sans-serif;
  --nfai-header-font-family: "Jura", sans-serif;
  --nfai-button-font-family: "DIN Next Rounded LT W04 Light", "Arial", sans-serif;
  --nfai-mono-font-family: "DIN Next Slab Pro", "Menlo Regular", monospace;

  --nfai-black: #1a1a1a;
  --nfai-dark-grey: #363636;
  --nfai-middle-grey: #6d7377;
  --nfai-washed-white: #c1c1c1;
  --nfai-white: #ffffff;
  --nfai-cyan: #1a9faf;
  --nfai-darkcyan: #0093a3;
  --nfai-lightcyan: #33aebe;
  --nfai-yellow: #ecc85a;
  --nfai-orange: #fd7a52;
  --nfai-red: #ee1212;
  --nfai-green: #4fac43;
  --nfai-background-color: var(--nfai-black);
  --nfai-text-color: var(--nfai-washed-white);
  --nfai-button-color: var(--nfai-yellow);

  --nfai-font-size-small: calc(11px);

  background-color: var(--nfai-cyan);
  background-image: url(assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: var(--nfai-white);
  font-size: 13pt;
  line-height: 1.3;
  border-radius: 5pt;
}

#main {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  /* Firefox */
  -webkit-animation: fadein 0.5s;
  /* Safari and Chrome */
  -o-animation: fadein 1s;
  /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin { 
  from { 
      transform: rotate(0deg); 
  } to { 
      transform: rotate(360deg); 
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {

  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body {
  color: var(--nfai-black);
  font-family: var(--nfai-text-font-family)
}

h3 {
  font-family: var(--nfai-text-font-family);
  text-align: center;
  font-size: 14pt;
  color: var(--nfai-black);
}

.navbar {
  background-color: var(--nfai-black);
  color: var(--nfai-washed-white);
  list-style-type: none;
}

.active {
  cursor: pointer;
  /* transition: transform .1s; */
}

p.active:hover {
  text-decoration: underline;
}

span.active:hover {
  text-decoration: underline;
}

.button {
  font-family: var(--nfai-button-font-family);
  font-size: 11pt;
  background-color: var(--nfai-yellow);
  border: 1;
  border-color: var(--nfai-black);
  border-style: solid;
  outline: none;
}

.stopwatch_icon {
  mask-image: url(assets/stopwatch.png);
  mask-size: 18pt;
  /* -webkit-mask-image: url(assets/stopwatch.png); */
}

.fastfwd_icon {
  mask-image: url(assets/fast_forward.png);
  mask-size: 18pt;
}


dialog {
 background-color: var(--nfai-darkcyan);
 color: var(--nfai-black);
 border-radius: 5pt;
 border-style: solid;
 border-color: var(--nfai-darkcyan);
 box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
 outline: none;
 padding: 0;
}

dialog::backdrop {
  background: hsl(0 0% 0% / 70%);
}